<template>
  <section class="case-section">
    <div v-if="anchor" :id="anchor" class="case-section__anchor"></div>
    <NuxtImg
      v-if="sectionBanner"
      class="case-section__banner"
      :src="sectionBanner"
      :width="bannerSizes.width"
      :height="bannerSizes.height"
      provider="localProvider"
    />
    <div class="case-section__content">
      <MainPageSectionTitle v-bind="titleOptions" />
      <div class="case-section__content-list">
        <div v-if="firstLine" class="case-section__content-list--block">
          <LazySharedKitCase
            v-for="item in firstLine"
            :key="item.name"
            :event-data="eventData"
            :case-data="item"
            :variant="{
              colorData: colorsOptions,
              locked: !!item.locked,
              type: 'default',
              showRag: false,
            }"
          />
        </div>
        <div v-if="otherLine" class="case-section__content-list--block">
          <LazySharedKitCase
            v-for="item in otherLine"
            :key="item.name"
            :event-data="eventData"
            :case-data="item"
            :variant="{
              colorData: colorsOptions,
              locked: !!item.locked,
              type: 'default',
              showRag: false,
            }"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { ICaseSectionProps } from './CaseSection.types';
const props = defineProps<ICaseSectionProps>();

const changeXL = GlobalUtils.Media.changeByMedia('xl');

const firstLine = computed(() => props.cases.slice(0, props.lineBreaks ?? 0));
const otherLine = computed(() => props.cases.slice(props.lineBreaks ?? 0));

const bannerSizes = computed(() => {
  return {
    height: changeXL(560, 278),
    width: changeXL(1884, 938),
  };
});
</script>

<style scoped lang="scss" src="./CaseSection.scss" />
