import { useBattlepassStore } from './battlepass.store';
import { useAuthStore } from '~/features/authentication/store/authorization';
import { EBattlepassLevelState, EBattlepassLevelType } from '~/features/mainPage/Types/battlepass/client.types';

/* Хранилище уровней батлпасса */
export const useBattlepassLevelsStore = defineStore('mainPage/battlepass/levels', () => {
  const {
    $i18n: { t },
  } = useNuxtApp();

  /* Import */
  /* Хранилище батлпасса */
  const battlepassStore = useBattlepassStore();
  const authStore = useAuthStore();

  /* Getters */
  /* Текущий активный уровень */
  const activeLevel = computed(() => {
    if (!authStore.isAuth) return null;

    if (!battlepassStore.levels.length) return null;
    return battlepassStore.levels.find((level) => level.state === EBattlepassLevelState.CURRENT) ?? null;
  });

  const formattedCurrentCoins = computed(() => {
    if (!activeLevel.value) return 0;
    return battlepassStore.currentCoins - activeLevel.value.costDiff;
  });

  /* Закончен ли батлпасс */
  const isFinished = computed(() => {
    if (!authStore.isAuth) return false;

    if (!battlepassStore.levels.length || battlepassStore.currentCoins === 0) return false;
    return !activeLevel.value;
  });

  /* Информация о оставшихся арканах */
  const remainingRares = computed(() => {
    const currentLevel = activeLevel.value?.level ?? 0;

    const rareLevels = battlepassStore.levels.filter(
      (level) => level.type === EBattlepassLevelType.RARE && level.level > currentLevel,
    );
    const rareLevelsLength = rareLevels.length;
    if (!rareLevelsLength) return null;

    const titleDeclamation = t('mainPage.battlepass.point2Declamations', rareLevelsLength);

    return {
      color: GlobalUtils.Colors.getColorsRarity('arcana'),
      img: battlepassStore.icons.rare,
      remainingLevels: rareLevels[0].level - currentLevel,
      title: t('mainPage.battlepass.point2Title', { amount: rareLevelsLength, rarity: titleDeclamation }),
    };
  });

  /* Информация о главной награде */
  const remainingUltra = computed(() => {
    const currentLevel = activeLevel.value?.level ?? 0;

    const ultraLevel = battlepassStore.levels.find((level) => level.type === EBattlepassLevelType.ULTRA);
    if (!ultraLevel) return null;

    return {
      color: GlobalUtils.Colors.getColorsRarity('immortal'),
      img: battlepassStore.icons.ultra,
      remainingLevels: ultraLevel.level - currentLevel,
      title: t('mainPage.battlepass.point3Title'),
    };
  });

  return {
    activeLevel,
    formattedCurrentCoins,
    isFinished,
    remainingRares,
    remainingUltra,
  };
});
