<template>
  <SkeletonBaseBackground class="battlepass-card" v-bind="cardSize" :border-radius="12">
    <SkeletonBaseForeground
      class="battlepass-card__image"
      :width="imageSize"
      :height="imageSize"
      :border-radius="600"
    />
    <div class="battlepass-card__texts">
      <SkeletonBaseForeground :width="changeXL(96, 80)" :height="textHeight" :border-radius="4" />
      <SkeletonBaseForeground :width="changeXL(152, 120)" :height="textHeight" :border-radius="4" />
    </div>
    <SkeletonBaseForeground
      class="battlepass-card__bottom"
      :width="bottomSize"
      :height="bottomSize"
      :border-radius="600"
    />
  </SkeletonBaseBackground>
</template>

<script setup lang="ts">
const { changeByMedia } = GlobalUtils.Media;
const changeXL = changeByMedia('xl');

const cardSize = computed(() => ({
  height: changeXL(285, 188),
  width: changeXL(200, 144),
}));

const imageSize = computed(() => changeXL(144, 96));

const textHeight = computed(() => changeXL(20, 12));

const bottomSize = computed(() => changeXL(30, 24));
</script>

<style scoped lang="scss" src="./BattlepassCard.scss" />
