<template>
  <SkeletonComponentLoader>
    <section v-if="!isLoaded" class="battlepass-controller-skeleton">
      <div class="battlepass-controller-skeleton__points">
        <SkeletonBaseForeground v-for="i in 4" :key="i" v-bind="pointsSize" />
      </div>
      <div class="battlepass-controller-skeleton__progress">
        <div class="battlepass-controller-skeleton__progress-levels">
          <SkeletonBattlepassCard v-for="i in 12" :key="i" />
        </div>
        <SkeletonBaseForeground
          class="battlepass-controller-skeleton__progress-bar"
          :height="barHeight"
          :border-radius="0"
        />
      </div>
    </section>
    <MainPageBattlepassController v-else />
  </SkeletonComponentLoader>
</template>

<script setup lang="ts">
import { useEventStore } from '~/store/event/event.store';
import { useBattlepassStore } from '~/features/mainPage/store/battlepass.store';

const eventStore = useEventStore();
const { isLoaded: eventLoaded } = storeToRefs(eventStore);

const battlepassStore = useBattlepassStore();
const { isLoaded: battlepassLoaded } = storeToRefs(battlepassStore);

const { changeByMedia } = GlobalUtils.Media;
const changeXL = changeByMedia('xl');
const changeMD = changeByMedia('md');

const isLoaded = computed(() => eventLoaded.value && battlepassLoaded.value);

const pointsSize = computed(() => ({
  borderRadius: changeXL(12, 6),
  height: changeXL(40, 32),
  width: changeXL(280, changeMD(200, 152)),
}));

const barHeight = computed(() => changeXL(10, 6));
</script>

<style scoped lang="scss" src="./BattlepassController.skeleton.scss" />
