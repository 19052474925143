<template>
  <section class="free-cases">
    <MainPageSectionTitle v-bind="freeCasesStore.titleOptions" />
    <div class="free-cases__list">
      <LazySharedKitCase
        v-for="item in freeCasesStore.cases"
        :key="item.name"
        :event-data="{
          color: eventStore.coinColor,
          icon: eventStore.coinIcon,
        }"
        :case-data="item"
        :variant="{
          type: 'default',
          showRag: false,
        }"
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import { useEventStore } from '~/store/event/event.store';
import { useFreeCasesStore } from '~/features/mainPage/store/freeCases.store';

const eventStore = useEventStore();
const freeCasesStore = useFreeCasesStore();
</script>

<style scoped lang="scss" src="./FreeCasesController.scss" />
